<template>
  <div>
    <MainAppBar>
      <template v-slot:title> Customers ({{ customerCount }}) </template>
    </MainAppBar>

    <div class="mt-5 px-12 mb-10">
      <v-row>
        <v-col cols="12">
          <v-row align="center" style="max-width: 500px">
            <v-text-field
              background-color="grey lighten-3"
              placeholder="Search MyID, email or name"
              v-model="filter.search"
              @input="search"
              hide-details
              clearable
              rounded
              flat
              solo
            />
          </v-row>
        </v-col>

        <v-col>
          <div
            class="mt-5 mb-10"
            id="customer-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="loading"
            infinite-scroll-distance="100"
          >
            <simple-table
              :loading="loading"
              :headers="tableHeaders"
              :collection="customers"
              :sort="sort"
              @headerClicked="sortCustomers"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
} from '@mdi/js'

import MainAppBar from '@/layouts/shared/MainAppBar'
import ControlsMixin from '@/utils/mixins/Controls'
import SimpleTable from '@/components/SimpleTable'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'CustomersPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    SimpleTable,
    MainAppBar,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,

      filter: {
        search: '',
      },

      sort: 'hash_id',

      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
      },
    }
  },

  created() {
    this.clearCustomers()
    this.fetchCustomers(1)
  },

  destroyed() {
    this.clearCustomers()
  },

  watch: {
    sort: function (newSort, oldSort) {
      this.clearCustomers()
      this.fetchCustomers()
    },
  },

  computed: {
    ...mapState({
      customers: (state) => state.customer.list,
      listMeta: (state) => state.customer.listMeta,
    }),
    ...mapGetters('customer', ['customerCount']),

    paginationLength() {
      return this.listMeta.last_page | 0
    },

    tableHeaders() {
      return [
        { property: 'hash_id', name: 'MyID' },
        { property: 'email', width: '25%' },
        { property: 'contact_number', width: '10%' },
        { property: 'first_name', width: '10%' },
        { property: 'last_name', width: '10%' },
        { property: 'address', name: 'Address', sortable: false, width: '20%' },
        {
          property: 'green_overall_verification_status',
          name: 'GreendID Status',
          width: '10%',
        },
        {
          property: 'created_at',
          name: 'Date added',
          width: '25%',
        },
      ]
    },
  },

  methods: {
    ...mapActions({
      getCustomers: 'customer/getCustomers',
    }),

    ...mapMutations({
      clearCustomers: 'customer/clearCustomerList',
    }),

    sortCustomers(property) {
      this.sort = (this.sort.startsWith('-') ? '' : '-') + property
    },

    pageChanged(page) {
      this.fetchCustomers(page)
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchCustomers(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearCustomers()
      this.fetchCustomers()
    }, 600),

    async fetchCustomers(page = 1) {
      if (this.loading) return

      const params = {
        page,
        sort: this.sort,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getCustomers(params)
      this.loading = false
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },
  },
}
</script>
